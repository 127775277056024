import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/KimchiSwap/Final Kwikswap 25-12-2020/Main Repos/Beadswap/beadswapfrontend/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When trading from a smart contract, the most important thing to keep in mind is that access to an external price source is `}<em parentName="p">{`required`}</em>{`. Without this, trades can be frontrun for considerable loss.`}</p>
    <p><em parentName="p">{`Read `}<a parentName="em" {...{
          "href": "#safety-considerations"
        }}>{`safety considerations`}</a>{` for more.`}</em></p>
    <h1 {...{
      "id": "using-the-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-the-router",
        "aria-label": "using the router permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using the Router`}</h1>
    <p>{`The easiest way to safely swap tokens is to use the `}<Link to='/docs/v1/smart-contracts/router02' mdxType="Link">{`router`}</Link>{`, which provides a variety of methods to safely swap to and from different assets. You’ll notice that there is a function for each permutation of swapping to/from an exact amount of BNB/tokens.`}</p>
    <p>{`First you must use an external price source to calculate the safety parameters for the function you’d like to call. This is either a minimum amount received when selling an exact input or the maximum amount you are willing to pay when a buying an exact output amount`}</p>
    <p>{`It is also important to ensure that your contract controls enough BNB/tokens to make the swap, and has granted approval to the router to withdraw this many tokens.`}</p>
    <p><em parentName="p">{`Check out the `}<Link to='/docs/v1/advanced-topics/pricing/#pricing-trades' mdxType="Link">{`Pricing`}</Link>{` page for a more in depth discussion on getting prices.`}</em></p>
    <h1 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h1>
    <p>{`Imagine you want to swap 50 DAI for as much BNB as possible from your smart contract.`}</p>
    <h2 {...{
      "id": "transferfrom",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#transferfrom",
        "aria-label": "transferfrom permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`transferFrom`}</h2>
    <p>{`Before swapping, our smart contracts needs to be in control of 50 DAI. The easiest way to accomplish this is by calling `}<inlineCode parentName="p">{`transferFrom`}</inlineCode>{` on DAI with the owner set to `}<inlineCode parentName="p">{`msg.sender`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`uint amountIn = 50 * 10 ** DAI.decimals();
require(DAI.transferFrom(msg.sender, address(this), amountIn), 'transferFrom failed.');
`}</code></pre>
    <h2 {...{
      "id": "approve",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#approve",
        "aria-label": "approve permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`approve`}</h2>
    <p>{`Now that our contract owns 50 DAI, we need to approve to the `}<Link to='/docs/v1/smart-contracts/router02' mdxType="Link">{`router`}</Link>{` to withdraw this DAI:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`require(DAI.approve(address(BeadswapV1Router02), amountIn), 'approve failed.');
`}</code></pre>
    <h2 {...{
      "id": "swapexacttokensforbnb",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapexacttokensforbnb",
        "aria-label": "swapexacttokensforbnb permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapExactTokensForBNB`}</h2>
    <p>{`Now we’re ready to swap:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`// amountOutMin must be retrieved from an oracle of some kind
address[] memory path = new address[](2);
path[0] = address(DAI);
path[1] = BeadswapV1Router02.WBNB();
BeadswapV1Router02.swapExactTokensForBNB(amountIn, amountOutMin, path, msg.sender, block.timestamp);
`}</code></pre>
    <h1 {...{
      "id": "safety-considerations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#safety-considerations",
        "aria-label": "safety considerations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Safety Considerations`}</h1>
    <p>{`Because Binance Smart Chain transactions occur in an adversarial environment, smart contracts that do not perform safety checks `}<em parentName="p">{`can be exploited for profit`}</em>{`. If a smart contract assumes that the current price on Beadswap is a “fair” price without performing safety checks, `}<em parentName="p">{`it is vulnerable to manipulation`}</em>{`. A bad actor could e.g. easily insert transactions before and after the swap (a “sandwich” attack) causing the smart contract to trade at a much worse price, profit from this at the trader’s expense, and then return the contracts to their original state. (One important caveat is that these types of attacks are mitigated by trading in extremely liquid pools, and/or at low values.)`}</p>
    <p>{`The best way to protect against these attacks is to use an external price feed or “price oracle”. The best “oracle” is simply `}<em parentName="p">{`traders’ off-chain observation of the current price`}</em>{`, which can be passed into the trade as a safety check. This strategy is best for situations `}<em parentName="p">{`where users initiate trades on their own behalf`}</em>{`.`}</p>
    <p>{`However, when an off-chain price can’t be used, an on-chain oracle should be used instead. Determining the best oracle for a given situation is a not part of this guide, but for more details on the Beadswap approach to oracles, see `}<Link to='/docs/v1/core-concepts/oracles' mdxType="Link">{`Oracles`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      